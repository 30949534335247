export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

export async function checkIfOnWaitlist(number) {
    return fetch(process.env.REACT_APP_API_URL + 'waitlist/' + number, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access Code': process.env.REACT_APP_API_KEY
        },
    }).then(data => data.json()).catch(console.error);
}

export async function addToWaitlist(number) {
    return fetch(process.env.REACT_APP_API_URL + 'waitlist/new/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access Code': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify({
            number,
            date: new Date().getTime(),
            inviteId: generateRandomString(),
            invited: false
        })
    }).then(data => data.json()).catch(console.error);
}

function generateRandomString() {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}