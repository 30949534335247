import { MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { BeatLoader, ScaleLoader } from "react-spinners";
import { addToWaitlist, checkIfOnWaitlist, isNumeric } from "../utils/utils";

export default function Waitlist() {
    const [number, setNumber] = useState("");
    const [working, setWorking] = useState(false);
    const countryCodes = [
        { country: "GH", code: "+233" },
        { country: "NG", code: "+234" },
        { country: "UK", code: "+44" }
    ];
    const [countryCode, setCountryCode] = useState(countryCodes[2].code);



    async function validateFields() {
        if (number.length < 7) {
            toast("Please enter a phone number of valid length 🙁")
        } else {
            setWorking(true)
            let num = number;
            if (num.charAt(0) === '0') {
                num = num.substring(1, num.length);
            }
            num = countryCode.concat(num);
            // console.log(num);
            const checkuser = await checkIfOnWaitlist(num);
            console.log(checkuser.message);
            setWorking(false);
            if (checkuser.message) {
                toast("You've already signed up to be notified 😊");
            } else {
                const res = await addToWaitlist(num);
                console.log(res);
                if (res.status == true) {
                    toast("Thanks for signing up! We'll send you a code and instructions when it's ready 😊");
                    setNumber("");
                } else {
                    toast("Someting went wrong, please try again.");
                }
            }
        }
    }
    return (
        <div>
        <Toaster />
            {working ?
                <ScaleLoader />
                :
                <div className="form-no-bg">
                    <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={countryCode}
                            label="Country code"
                            onChange={(e) => {
                                setCountryCode(e.target.value);
                            }}
                        >
                            {
                                countryCodes.map((item, index) => {
                                    return (
                                        <MenuItem value={item.code}>{item.country} {item.code}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <TextField
                            id="outlined-basic"
                            label="Enter phone number"
                            variant="outlined"
                            type="numeric"
                            value={number}
                            onChange={(e) => {
                                if (!isNumeric(e.target.value)) {
                                    setNumber("")
                                } else {
                                    setNumber(e.target.value)
                                }
                            }} />
                    </div>
                    <button
                        style={{ marginTop: 0 }}
                        size="md"
                        className="primary-btn"
                        onClick={() => {
                            validateFields();
                        }}
                    >
                        Sign up to beta test
                    </button>
                </div>}
        </div>

    )
}