import React, { useState } from "react";
import logo from './clogo.png';
import Waitlist from "./components/Waitlist";

function App() {
  return (
    <div className="landing-body">
      <div className="landing-main">
        <div className="icon-text">
          <img src={logo} className="logo" />
          <h1 className="logo-text">Circleclub</h1>
        </div>
        <h1 style={{ marginTop: 24, marginBottom: 0 }}>Do more with your money in groups</h1>
        <p >Rotate savings, run giveaways, gift friends or strangers.</p>
        <p>We're currently in a private beta. If you're interested in joining that beta, enter your phone number and we'll text you a personal invite link when there's a space for you!</p>
        <Waitlist />

      </div>
      <div className="footer">
        <div className="footer-links">
        <a href="mailTo:hello@joincircleclub.com">Email us</a>
          <a href="#">Terms</a>
          <a href="#">Privacy</a>
          <a href="#">Security</a>
        </div>
      </div>
    </div>
  );
}

export default App;
